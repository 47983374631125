<template>
    <admin-template>
        <template slot="drawers">
            <user-reset-password-drawer
                v-model="showResetPasswordDrawer"
                :user="selectedEmployeeUser"
            />

            <employee-create-drawer v-model="showCreateDrawer" />

            <employee-edit-drawer v-model="showEditDrawer" :employee="selectedEmployee">
                <template v-slot:actions="{ employee }">
                    <v-col class="flex-grow-0">
                        <v-menu bottom left>
                            <template v-slot:activator="{ on }">
                                <v-btn icon small v-on="on">
                                    <v-icon>mdi-dots-horizontal</v-icon>
                                </v-btn>
                            </template>

                            <v-list>
                                <v-list-item @click="onResetPasswordSelected(employee)">
                                    <v-list-item-title>Cambiar contraseña</v-list-item-title>
                                </v-list-item>
                            </v-list>
                        </v-menu>
                    </v-col>
                </template>
            </employee-edit-drawer>
        </template>

        <template slot="content">
            <v-toolbar flat dense color="transparent" height="60" class="mb-2">
                <v-toolbar-title>
                    <span class="headline">Empleados</span>
                </v-toolbar-title>

                <v-spacer></v-spacer>

                <v-toolbar-title>
                    <v-tooltip bottom>
                        <template v-slot:activator="{ on }">
                            <v-btn
                                fab
                                x-small
                                color="primary"
                                class="elevation-0"
                                @click.stop="onNewSelected"
                                v-on="on"
                            >
                                <v-icon>mdi-plus</v-icon>
                            </v-btn>
                        </template>
                        <span>Nuevo empleado</span>
                    </v-tooltip>
                </v-toolbar-title>
            </v-toolbar>

            <v-row>
                <v-col cols="12">
                    <v-card>
                        <v-card-text>
                            <v-data-table
                                hide-default-footer
                                hide-default-header
                                :items="employees"
                                :loading="isLoading"
                                :headers="headers"
                                :items-per-page="10"
                                :page.sync="pagination.page"
                                :server-items-length="totalEmployees"
                                @click:row="onEmployeeSelected"
                            >
                                <template
                                    v-slot:item.fullName="{ item }"
                                >{{ item.fullName | capitalize }}</template>

                                <template v-slot:item.user.isActive="{ item }">
                                    <v-icon v-if="item.user.isActive">mdi-check</v-icon>
                                </template>
                            </v-data-table>
                        </v-card-text>
                    </v-card>
                </v-col>
            </v-row>
        </template>
    </admin-template>
</template>

<script>
import AdminTemplate from '../AdminTemplate.vue';
import EmployeeCreateDrawer from '@/pages/Admin/EmployeeList/EmployeeCreateDrawer';
import EmployeeEditDrawer from '@/pages/Admin/EmployeeList/EmployeeEditDrawer';
import UserResetPasswordDrawer from '@/components/User/UserResetPasswordDrawer';

export default {
    components: {
        AdminTemplate,
        EmployeeCreateDrawer,
        EmployeeEditDrawer,
        UserResetPasswordDrawer
    },

    data() {
        return {
            showCreateDrawer: false,
            showEditDrawer: false,
            showResetPasswordDrawer: false,

            selectedEmployee: null,

            filters: {

            },

            pagination: {
                page: 1,
                itemsPerPage: 25
            },

            headers: [
                { text: 'Nombre', value: 'fullName' },
                { text: 'Email', value: 'user.email' },
                { text: 'Rol', value: 'user.roles' },
                { text: 'Activo', value: 'user.isActive' }
            ]
        };
    },

    computed: {
        isLoading() {
            return this.$store.getters['employees/isLoading']();
        },

        employees() {
            return this.$store.getters['employees/getAll']();
        },

        totalEmployees() {
            return this.$store.getters['employees/getTotal']();
        },

        selectedEmployeeUser() {
            if (!this.selectedEmployee) {
                return null;
            }

            return this.selectedEmployee.user;
        }
    },

    watch: {
        showEditDrawer(val) {
            if (val) {
                this.showCreateDrawer = false;
            }
        },

        showCreateDrawer(val) {
            if (val) {
                this.showEditDrawer = false;
            }
        },

        pagination: {
            handler() {
                this.filters.offset = (this.pagination.page - 1) * this.pagination.itemsPerPage;
                this.filters.limit = this.pagination.itemsPerPage;

                this.onFiltersChanged();
            },

            deep: true
        }
    },

    timers: {
        fetchTimer: {
            time: 400,
            autostart: false
        }
    },

    mounted() {
        if (this.pagination) {
            this.filters.offset = (this.pagination.page - 1) * this.pagination.itemsPerPage;
            this.filters.limit = this.pagination.itemsPerPage;
        }

        this.onFiltersChanged();
    },

    methods: {
        fetchTimer() {
            this.$timer.stop('fetchTimer');
            this.fetchEmployees();
        },

        onFiltersChanged() {
            this.$timer.stop('fetchTimer');
            this.$timer.start('fetchTimer');

            // this.updateRouteQuery();
        },

        onNewSelected() {
            this.showCreateDrawer = true;
            this.selectedEmployee = null;
        },

        onEmployeeSelected(employee) {
            this.showEditDrawer = true;
            this.selectedEmployee = employee;
        },

        onResetPasswordSelected(employee) {
            this.showEditDrawer = false;
            this.showResetPasswordDrawer = true;
            this.selectedEmployee = employee;
        },

        fetchEmployees() {
            let filters = {};
            filters = this.filters;

            this.$store.dispatch('employees/fetchAll', {});
        }
    }
};
</script>

<style lang="scss" scoped>
</style>
