<template>
    <v-navigation-drawer
        v-model="active"
        disable-resize-watcher
        right
        temporary
        fixed
        width="600"
        style="z-index: 12"
    >
        <v-container class="px-6">
            <v-row>
                <v-col>
                    <v-row class="align-center">
                        <v-btn icon class="black--text" @click="onBackSelected">
                            <v-icon>mdi-chevron-left</v-icon>
                        </v-btn>
                        <span class="headline" style="white-space: nowrap">Cambiar contraseña</span>
                    </v-row>
                </v-col>
            </v-row>

            <v-row>
                <v-col cols="12">
                    <v-list-item class="px-0">
                        <v-list-item-avatar size="32" class="ml-0 mr-2">
                            <img src="https://cdn.vuetifyjs.com/images/john.jpg" alt="John">
                        </v-list-item-avatar>

                        <v-list-item-content>
                            <v-list-item-title class="grey--text caption py-0 my-0">Usuario</v-list-item-title>
                            <v-list-item-subtitle class="black--text body-2 py-0 my-0"></v-list-item-subtitle>
                        </v-list-item-content>
                    </v-list-item>
                </v-col>
            </v-row>

            <v-row>
                <v-col cols="12">
                    <validation-provider name="password" v-slot="{ errors }" rules="required">
                        <v-text-field
                            v-model="password"
                            :label="$t('fields.password')"
                            :append-icon="showPassword ? 'mdi-eye' : 'mdi-eye-off'"
                            :type="showPassword ? 'text' : 'password'"
                            @click:append="showPassword = !showPassword"
                            :error-messages="errors"
                            autocomplete="new-password"
                        />
                    </validation-provider>
                </v-col>
            </v-row>
        </v-container>

        <template v-slot:append>
            <v-row class="ma-4">
                <v-spacer></v-spacer>

                <v-btn color="green" block dark @click="onConfirmSelected" :loading="isSaving">Guardar</v-btn>
            </v-row>
        </template>
    </v-navigation-drawer>
</template>

<script>
    import { ValidationProvider } from 'vee-validate';

    export default {
        components: {
            ValidationProvider
        },

        props: {
            value: Boolean,
            user: Object
        },

        data() {
            return {
                active: false,
                isSaving: false,
                showPassword: true,
                password: ''
            };
        },

        computed: {
        },

        watch: {
            active(val) {
                this.$emit('input', val);
            },

            value(val) {
                this.active = val;
            }
        },

        mounted() {
            this.password = Math.random().toString(36).slice(-8);
        },

        methods: {
            onBackSelected() {
                this.active = false;
            },

            async onConfirmSelected() {
                this.isSaving = true;

                try {
                    await this.$api.setNewPassword(this.user.email, this.password, '');
                }
                finally {
                    this.isSaving = false;
                }

                this.$store.dispatch('app/setStatusMessage', 'Contraseña guardada');

                this.active = false;
            }
        }
    };
</script>

<style lang="scss">

</style>
