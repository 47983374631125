var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('admin-template',[_c('template',{slot:"drawers"},[_c('user-reset-password-drawer',{attrs:{"user":_vm.selectedEmployeeUser},model:{value:(_vm.showResetPasswordDrawer),callback:function ($$v) {_vm.showResetPasswordDrawer=$$v},expression:"showResetPasswordDrawer"}}),_c('employee-create-drawer',{model:{value:(_vm.showCreateDrawer),callback:function ($$v) {_vm.showCreateDrawer=$$v},expression:"showCreateDrawer"}}),_c('employee-edit-drawer',{attrs:{"employee":_vm.selectedEmployee},scopedSlots:_vm._u([{key:"actions",fn:function(ref){
var employee = ref.employee;
return [_c('v-col',{staticClass:"flex-grow-0"},[_c('v-menu',{attrs:{"bottom":"","left":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
return [_c('v-btn',_vm._g({attrs:{"icon":"","small":""}},on),[_c('v-icon',[_vm._v("mdi-dots-horizontal")])],1)]}}],null,true)},[_c('v-list',[_c('v-list-item',{on:{"click":function($event){return _vm.onResetPasswordSelected(employee)}}},[_c('v-list-item-title',[_vm._v("Cambiar contraseña")])],1)],1)],1)],1)]}}]),model:{value:(_vm.showEditDrawer),callback:function ($$v) {_vm.showEditDrawer=$$v},expression:"showEditDrawer"}})],1),_c('template',{slot:"content"},[_c('v-toolbar',{staticClass:"mb-2",attrs:{"flat":"","dense":"","color":"transparent","height":"60"}},[_c('v-toolbar-title',[_c('span',{staticClass:"headline"},[_vm._v("Empleados")])]),_c('v-spacer'),_c('v-toolbar-title',[_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
return [_c('v-btn',_vm._g({staticClass:"elevation-0",attrs:{"fab":"","x-small":"","color":"primary"},on:{"click":function($event){$event.stopPropagation();return _vm.onNewSelected.apply(null, arguments)}}},on),[_c('v-icon',[_vm._v("mdi-plus")])],1)]}}])},[_c('span',[_vm._v("Nuevo empleado")])])],1)],1),_c('v-row',[_c('v-col',{attrs:{"cols":"12"}},[_c('v-card',[_c('v-card-text',[_c('v-data-table',{attrs:{"hide-default-footer":"","hide-default-header":"","items":_vm.employees,"loading":_vm.isLoading,"headers":_vm.headers,"items-per-page":10,"page":_vm.pagination.page,"server-items-length":_vm.totalEmployees},on:{"update:page":function($event){return _vm.$set(_vm.pagination, "page", $event)},"click:row":_vm.onEmployeeSelected},scopedSlots:_vm._u([{key:"item.fullName",fn:function(ref){
var item = ref.item;
return [_vm._v(_vm._s(_vm._f("capitalize")(item.fullName)))]}},{key:"item.user.isActive",fn:function(ref){
var item = ref.item;
return [(item.user.isActive)?_c('v-icon',[_vm._v("mdi-check")]):_vm._e()]}}])})],1)],1)],1)],1)],1)],2)}
var staticRenderFns = []

export { render, staticRenderFns }