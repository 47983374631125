<template>
    <v-navigation-drawer
        v-model="active"
        right
        temporary
        fixed
        width="600"
    >
        <v-container class="pa-10">
            <v-row>
                <v-col>
                    <v-row class="align-center">
                        <v-btn icon class="black--text" @click="onBackSelected">
                            <v-icon>mdi-chevron-left</v-icon>
                        </v-btn>
                        <span class="headline">Nuevo empleado</span>
                    </v-row>
                </v-col>
            </v-row>

            <v-row>
                <validation-observer ref="form">
                    <v-form ref="form" v-model="valid">
                        <v-container>
                            <v-row>
                                <v-col cols="12" sm="6">
                                    <validation-provider name="firstName" v-slot="{ errors }" rules="required">
                                        <v-text-field
                                            v-model="data.firstName"
                                            label="Nombre"
                                            :error-messages="errors"
                                        />
                                    </validation-provider>
                                </v-col>

                                <v-col cols="12" sm="6">
                                    <validation-provider name="lastName" v-slot="{ errors }" rules="required">
                                        <v-text-field
                                            v-model="data.lastName"
                                            label="Apellido"
                                            :error-messages="errors"
                                        />
                                    </validation-provider>
                                </v-col>

                                <v-col cols="12">
                                    <validation-provider name="email" v-slot="{ errors }" rules="required|email">
                                        <v-text-field
                                            v-model="data.email"
                                            label="Email"
                                            :error-messages="errors"
                                            autocomplete="new-email"
                                        />
                                    </validation-provider>
                                </v-col>

                                <v-col cols="12">
                                    <validation-provider name="password" v-slot="{ errors }" rules="required">
                                        <v-text-field
                                            v-model="data.password"
                                            label="Password"
                                            type="password"
                                            :error-messages="errors"
                                            autocomplete="new-password"
                                        />
                                    </validation-provider>
                                </v-col>

                                <v-col cols="12">
                                    <validation-provider name="roles" v-slot="{ errors }" rules="required">
                                        <v-autocomplete
                                            v-model="data.roles"
                                            :items="roles"
                                            label="Roles"
                                            multiple
                                            :error-messages="errors"
                                        />
                                    </validation-provider>
                                </v-col>
                            </v-row>
                        </v-container>
                    </v-form>
                </validation-observer>

                <v-spacer></v-spacer>
            </v-row>
        </v-container>

        <template v-slot:append>
            <v-row class="ma-4">
                <v-spacer></v-spacer>

                <v-btn color="green" dark block @click="onSaveSelected" :loading="isSaving">Guardar</v-btn>
            </v-row>
        </template>
    </v-navigation-drawer>
</template>

<script>
    import { ValidationProvider, ValidationObserver } from 'vee-validate';

    export default {
        components: {
            ValidationProvider,
            ValidationObserver
        },

        props: {
            value: {
                type: Boolean,
                default: false
            }
        },

        data() {
            return {
                active: false,

                roles: [ 'ROLE_ADMIN', 'ROLE_CHOFER', 'ROLE_HUERTA' ],
                valid: false,
                showSuccessMessage: true,

                data: {
                    firstName: '',
                    lastName: '',
                    email: '',
                    password: '',
                    roles: []
                }
            };
        },

        computed: {
            isSaving() {
                return this.$store.getters['employees/isSaving']();
            }
        },

        watch: {
            active(val) {
                this.$emit('input', val);
            },

            value(val) {
                this.active = val;
            }
        },

        methods: {
            async onSaveSelected() {
                let result = await this.$refs.form.validate();
                if(!result) {
                    return;
                }

                try {
                    await this.$store.dispatch('employees/create', { data: this.data });

                    this.$store.dispatch('app/setStatusMessage', 'Empleado creado');

                    this.active = false;
                }
                catch (err) {
                    let formErrors = {};

                    if(err.code === 'error.user.password_invalid') {
                        formErrors.password = this.$t(err.code);
                    }
                    else {
                        for(let errField in err.errors) {
                            formErrors[errField] = this.$t(err.errors[errField]);
                        }
                    }

                    this.$refs.form.setErrors(formErrors);
                }
            },

            onBackSelected() {
                this.active = false;
            }
        }
    };
</script>
