var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-navigation-drawer',{attrs:{"right":"","temporary":"","fixed":"","width":"600"},scopedSlots:_vm._u([{key:"append",fn:function(){return [_c('v-row',{staticClass:"ma-4"},[_c('v-spacer'),_c('v-btn',{attrs:{"color":"green","dark":"","block":"","loading":_vm.isSaving},on:{"click":_vm.onSaveSelected}},[_vm._v("Guardar")])],1)]},proxy:true}]),model:{value:(_vm.active),callback:function ($$v) {_vm.active=$$v},expression:"active"}},[(_vm.employee)?_c('v-container',{staticClass:"pa-10"},[_c('v-row',{attrs:{"align":"center"}},[_c('v-avatar',{attrs:{"color":"grey","size":"64"}},[_vm._v(" "+_vm._s(_vm._f("toInitials")(_vm.employee.fullName))+" ")]),_c('v-col',[_c('v-row',[_c('v-col',{staticClass:"headline blue-grey--text text--darken-3 py-0"},[_vm._v(" "+_vm._s(_vm.employee.fullName)+" ")])],1),_c('v-row',[_c('v-col',{staticClass:"text--lighten-1 grey--text py-0"},[_vm._v(" "+_vm._s(_vm.employee.user.username)+" ")])],1)],1),_vm._t("actions",null,{"employee":_vm.employee})],2),_c('v-row',[_c('v-col',{attrs:{"cols":"12"}},[_c('validation-observer',{ref:"form"},[_c('v-form',[_c('v-row',[_c('v-col',{attrs:{"cols":"12","sm":"6"}},[_c('validation-provider',{attrs:{"name":"firstName","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('v-text-field',{attrs:{"label":"Nombres","error-messages":errors},model:{value:(_vm.data.firstName),callback:function ($$v) {_vm.$set(_vm.data, "firstName", $$v)},expression:"data.firstName"}})]}}],null,false,2992781408)})],1),_c('v-col',{attrs:{"cols":"12","sm":"6"}},[_c('validation-provider',{attrs:{"name":"lastName","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('v-text-field',{attrs:{"label":"Apellidos","error-messages":errors},model:{value:(_vm.data.lastName),callback:function ($$v) {_vm.$set(_vm.data, "lastName", $$v)},expression:"data.lastName"}})]}}],null,false,1085914783)})],1),_c('v-col',{attrs:{"cols":"12"}},[_c('span',{staticClass:"title"},[_vm._v("Usuario")])]),_c('v-col',{attrs:{"cols":"12","sm":"6"}},[_c('v-text-field',{attrs:{"label":"Nombre de usuario","disabled":""},model:{value:(_vm.employee.user.username),callback:function ($$v) {_vm.$set(_vm.employee.user, "username", $$v)},expression:"employee.user.username"}})],1),_c('v-col',{attrs:{"cols":"12","sm":"6"}},[_c('validation-provider',{attrs:{"name":"email","rules":"required|email"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('v-text-field',{staticClass:"with-link",attrs:{"label":"Email","error-messages":errors},model:{value:(_vm.data.email),callback:function ($$v) {_vm.$set(_vm.data, "email", $$v)},expression:"data.email"}})]}}],null,false,13065048)})],1),_c('v-col',{attrs:{"cols":"12"}},[_c('validation-provider',{attrs:{"name":"roles","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('v-select',{attrs:{"items":_vm.roles,"attach":"","chips":"","label":"Roles","multiple":"","error-messages":errors},model:{value:(_vm.data.roles),callback:function ($$v) {_vm.$set(_vm.data, "roles", $$v)},expression:"data.roles"}})]}}],null,false,2020124310)})],1),_c('v-col',{attrs:{"cols":"12"}},[_c('v-checkbox',{staticClass:"my-0 py-0",attrs:{"label":"Activo","error-messages":_vm.errors},model:{value:(_vm.data.isActive),callback:function ($$v) {_vm.$set(_vm.data, "isActive", $$v)},expression:"data.isActive"}})],1)],1)],1)],1)],1)],1)],1):_vm._e()],1)}
var staticRenderFns = []

export { render, staticRenderFns }