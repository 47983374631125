<template>
    <v-navigation-drawer
        v-model="active"
        right
        temporary
        fixed
        width="600"
    >
        <v-container class="pa-10" v-if="employee">
            <v-row align="center">
                <v-avatar color="grey" size="64">
                    {{ employee.fullName | toInitials }}
                </v-avatar>

                <v-col>
                    <v-row>
                        <v-col class="headline blue-grey--text text--darken-3 py-0">
                            {{ employee.fullName }}
                        </v-col>
                    </v-row>
                    <v-row>
                        <v-col class="text--lighten-1 grey--text py-0">
                            {{ employee.user.username }}
                        </v-col>
                    </v-row>
                </v-col>

                <slot name="actions" v-bind:employee="employee"></slot>
            </v-row>

            <v-row>
                <v-col cols="12">
                    <validation-observer ref="form">
                        <v-form>
                            <v-row>
                                <v-col cols="12" sm="6">
                                    <validation-provider name="firstName" v-slot="{ errors }" rules="required">
                                        <v-text-field
                                            v-model="data.firstName"
                                            label="Nombres"
                                            :error-messages="errors"
                                        />
                                    </validation-provider>
                                </v-col>

                                <v-col cols="12" sm="6">
                                    <validation-provider name="lastName" v-slot="{ errors }" rules="required">
                                        <v-text-field
                                            v-model="data.lastName"
                                            label="Apellidos"
                                            :error-messages="errors"
                                        />
                                    </validation-provider>
                                </v-col>

                                <v-col cols="12">
                                    <span class="title">Usuario</span>
                                </v-col>

                                <v-col cols="12" sm="6">
                                    <v-text-field
                                        v-model="employee.user.username"
                                        label="Nombre de usuario"
                                        disabled
                                    />
                                </v-col>

                                <v-col cols="12" sm="6">
                                    <validation-provider name="email" v-slot="{ errors }" rules="required|email">
                                        <v-text-field
                                            class="with-link"
                                            v-model="data.email"
                                            label="Email"
                                            :error-messages="errors"
                                        />
                                    </validation-provider>
                                </v-col>

                                <v-col cols="12">
                                    <validation-provider name="roles" v-slot="{ errors }" rules="required">
                                        <v-select
                                            v-model="data.roles"
                                            :items="roles"
                                            attach
                                            chips
                                            label="Roles"
                                            multiple
                                            :error-messages="errors"
                                        />
                                    </validation-provider>
                                </v-col>

                                <v-col cols="12">
                                    <v-checkbox
                                        class="my-0 py-0"
                                        v-model="data.isActive"
                                        label="Activo"
                                        :error-messages="errors"
                                    />
                                </v-col>
                            </v-row>
                        </v-form>
                    </validation-observer>
                </v-col>
            </v-row>
        </v-container>

        <template v-slot:append>
            <v-row class="ma-4">
                <v-spacer></v-spacer>

                <v-btn color="green" dark block @click="onSaveSelected" :loading="isSaving">Guardar</v-btn>
            </v-row>
        </template>
    </v-navigation-drawer>
</template>

<script>
    import { ValidationProvider, ValidationObserver } from 'vee-validate';

    export default {
        components: {
            ValidationProvider,
            ValidationObserver
        },

        props: {
            value: {
                type: Boolean,
                default: false
            },

            employee: {
                type: Object,
                default: null
            }
        },

        data() {
            return {
                active: false,
                data: {},
                roles: ['ROLE_ADMIN', 'ROLE_CHOFER', 'ROLE_HUERTA', 'ROLE_CALLCENTER']
            };
        },

        computed: {
            isSaving() {
                return this.$store.getters['employees/isSaving']();
            }
        },

        watch: {
            active(val) {
                this.$emit('input', val);
            },

            value(val) {
                this.active = val;
            },

            employee: {
                immediate: true,
                handler(e) {
                    if(!e) {
                        return;
                    }

                    this.data = {
                        firstName: e.firstName,
                        lastName: e.lastName,
                        email: e.user.email,
                        isActive: e.user.isActive,
                        roles: e.user.roles
                    };
                }
            }
        },

        mounted() {
        },

        methods: {
            hasRole(role) {
                return this.user.roles.includes(role);
            },

            async onSaveSelected() {
                let result = await this.$refs.form.validate();
                if(!result) {
                    return;
                }

                try {
                    await this.$store.dispatch('employees/patch', {
                        employee: this.employee,
                        data: this.data
                    });

                    this.$store.dispatch('app/setStatusMessage', 'Cambios guardados');
                }
                catch (err) {
                    let formErrors = {};

                    for(let errField in err.errors) {
                        formErrors[errField] = this.$t(err.errors[errField]);
                    }

                    this.$refs.form.setErrors(formErrors);
                }
            }
        }
    };
</script>

<style lang="scss" scoped>
    .v-chip.small {
        height: 20px;
    }
</style>
